import controller from '@/controller'
import { isIOSPWA, MS_PWA, MS_PWA_AI } from './api/PWAUtil'
import { getState, setStoreWrapper } from './StoreManager'
import { getMyLSProp, loadMyLSProps } from './LocalStorageUtil'

export const isTWA = () => {
  const appId = getAppId()
  if (appId) {
    return true
  }

  if (document.referrer.includes('android-app://')) {
    return true
  }

  const url = new URL(location.href)
  if (!url || !url.searchParams) {
    return false
  }
  const getParam = url.searchParams.get('appid')// || url.searchParams.get('mobile')
  if (getParam) {
    return true
  }

  return false
}

export const getAppId = () => {
  const cashedAppId = getState('app.appId')
  if (cashedAppId) {
    return cashedAppId
  }
  if (document.referrer.includes('app-info://platform/microsoft-store') || ((Date.now() - new Date(loadMyLSProps(MS_PWA))) < 5000)) {
    const appid = MS_PWA //getMyLSProp('pwaAppId') ||
    setStoreWrapper('app.appId', appid)
    return appid
  }
  const url = new URL(location.href)
  const getParam = url.searchParams ? url.searchParams.get('appid') : null
  return getParam
}

export function getPlatformForConnect(){
  let platform = getState('platform')
  if (platform) {
    if (platform === 'iOS') {
      return 'ios'
    }
    return 'and'
  }

  const url = new URL(location.href)
  platform = url.searchParams ? url.searchParams.get('platform')  : null

  if (platform && platform === 'iOS') {
      return 'ios'
  }
  return 'and'
}

export function getGen() {
  const url = new URL(location.href)
  const getParam = url.searchParams ? url.searchParams.get('gen') : null

  return parseInt(controller.getState('wrapperGen') || getParam || 0)
}

export function isMSPWA() {
  const appID = getAppId()
  return appID === MS_PWA || appID === MS_PWA_AI
}
