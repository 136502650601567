import { create } from 'zustand'

const useAppStore = create(set => ({
    rtl: false,
    desktopSkin: false,
    localeLoaded: false,
    locale: {},
    statusBarH:0,
    lang: 'en',
    // setRtl: rtl => {
    //     set({ rtl })
    // },
    setDesktopSkin: desktopSkin => {
        set({ desktopSkin })
    },
    setLocales: (locale, rtl, lang) => {
        set({ locale, localeLoaded: true, rtl, lang })
    },
    getZLocaleValue: (key, replaceParams, noKey) => {
        // if (!useAppStore.getState().localeLoaded) {
        //     return noKey ? '' : key
        // }
        // let final = useAppStore.getState().locale[key]
        // if (!final) {
        //     return noKey ? '' : key
        // }
        // if (replaceParams) {
        //     Object.keys(replaceParams).map(key => {
        //         final = final.replace(key, replaceParams[key])
        //     })
        // }
        // return final
    }
}))

// export function setRtl(rtl) {
//     useAppStore.getState().setRtl(rtl)
// }

export const getZLocaleValueF = ( key, replaceParams, noKey ) => {
    if (!useAppStore.getState().localeLoaded) {
        return noKey ? '' : key
    }
    let final = useAppStore.getState().locale[key]
    if (!final) {
        return noKey ? '' : key
    }
    if (replaceParams) {
        Object.keys(replaceParams).map(key => {
            final = final.replace(key, replaceParams[key])
        })
    }
    return final
}
export function setDesktopSkin(desktopSkin) {
    useAppStore.getState().setDesktopSkin(desktopSkin)
}

export function checkDesktopSkin(){
    return useAppStore.getState().desktopSkin
}

export const useIsRTL = () => {
    return useAppStore.getState().rtl
}

export const useIsLocaleLoaded = () => {
    return useAppStore.getState().localeLoaded
}

export const getLang = () => {
    return useAppStore.getState().lang
}

export const setLocales = (locale, rtl, lang) => {
    useAppStore.getState().setLocales(locale, rtl, lang)
}

export default useAppStore
