import { state } from 'cerebral'
import { GOOD_FOR_12, GOOD_FOR_14, GOOD_FOR_16, GOOD_FOR_18 } from '@/modules/buffs'
import { isBuffActive } from '@/utils/buffUtils'
import { sendAmplitudeEvent } from '@/utils/GAEvent'
import { getUser } from '@/utils/userLoader'
import { PRIVATE_MSG_PERMISSION } from '@/utils/subscriptionUtil'
import config from '@/config'
import { getSessionId } from '@/utils'
import { getLocaleValue } from '../../../utils/localeUil'
import { MESSAGE_TYPE_UPLOAD } from '@/utils/manyConst'
import { getState, setStoreWrapper } from '../../../utils/StoreManager'
import { getMyUid, imVIP, isMyProfileSet } from '../../../utils/my'
import { getUserAgeRating } from '../../../utils/ageRating/ageRatingUtil'
import { checkOnline, getOnlineStatusForAmplitude, getStreamStatusForAmplitude } from '../../socket/actions'
import { getChatDraft, setChatDraft } from '../../../utils/inputUtil'
import { getDialog } from '../../../utils/chat/dialogUtil'
import { imAdmin, isSupport } from '../../../utils/adminUtil'
import { datingNeedProfile } from '../../posts/signals/postLike'
import { checkNotification, giftNotificationWithTimeout } from '../../auth/actions/notificationUtil'
import { isCyber } from '../../../utils/cyber/cyberUtil'
import { getInCollector } from '../../../zustand/useMyStore'
import { isMe } from '../../../utils'

const { dialogsUrl } = config

let typigTimeout
export default async ({ props, store, get }) => {
  const currentText = getChatDraft(props.chatKey)
  if (!currentText && !props.stickerId && !props.mediaFileUpload) {
    return
  }
  if (!isMyProfileSet() &&
      !imVIP() &&
      !isSupport(props.uid) &&
      !isMe(props.uid) &&
      !imAdmin() &&
      !isCyber(props.uid) &&
      checkOnline(props.uid)
  ) {
    datingNeedProfile('need_set_profile_message')
    return
  }

  checkNotification(props.uid)
  giftNotificationWithTimeout(props.uid)
  if (isCyber(props.uid)) {
    setStoreWrapper('cyberTyping', props.uid)
    if (typigTimeout) {
      clearTimeout(typigTimeout)
      typigTimeout = setTimeout(()=>{
        if (getState('cyberTyping')) {
          setStoreWrapper('cyberTyping', null)
        }
      }, 20000)
    }
  }

  const dialogs = getDialogs()
  const dialog = getDialog(props.dialog_id, props.uid)

  const message = {
    date: new Date().toISOString(),
    dialog_id: dialog.dialog_id,
    uid: get(state`auth.uid`)
  }

  if (props.stickerId) {
    message.message = props.stickerId
    message.message_type = 'sticker'
  } else if (props.mediaFileUpload) {
    message.message = getLocaleValue('file_type_post')
    message.message_type = MESSAGE_TYPE_UPLOAD
    message.fileData = props.fileData
  } else {
    message.message = currentText
    message.message_type = 'text'
  }

  dialog.date = message.date
  dialog.message = message.message
  dialog.messages.push(message)
  store.set('chat.dialogs', dialogs)
  setChatDraft(props.chatKey, '')

  if (props.mediaFileUpload) {
    //TODO запрос на списание монет за отправку файла
    return
  }

  sendDialogMessageReq(message, dialog.dialog_id, props.uid)
}

export async function sendDialogMessageReq(message, dialogId, uid) {
  const dialogs = getDialogs()
  const dialog = getDialog(dialogId, uid)
  const toUids = isMe(uid) ? [uid] : getDialogToUids(dialog)
  const value = {
    uids_to: toUids,
    message: message.message,
    message_type: message.message_type
  }

  if (message.cost) {
    value.cost = message.cost
  }

  if (getInCollector('aiMode') || isMe(uid)) {
    value.ai_chat_mode = true
  }

  const response = await fetch(`${dialogsUrl}/api/v1/dialogs`, {
    method: 'POST',
    body: JSON.stringify(value),
    headers: {
      'Content-Type': 'application/json',
      'ft-sid': getSessionId()
    },
  })
  if (!response.ok) return
  const result = await response.json()
  const { dialog_id, message_id } = result.result
  if (!dialog.dialog_id) {
    dialog.dialog_id = dialog_id
  }
  message.dialog_id = dialog_id
  message.message_id = message_id

  setStoreWrapper('chat.dialogs', dialogs)
  sendAnalyticsEvent(toUids[0])
}

function sendAnalyticsEvent(toUid) {
  const user = getUser(toUid) || {}
  const amData = {
    sent_coins: 0,
    subscription_type: '-1',
    age_rating: getUserAgeRating(toUid),
    online: getOnlineStatusForAmplitude(toUid),
    stream: getStreamStatusForAmplitude(toUid),
    cyber: isCyber(toUid),
    counterparty_id: toUid || '-1',
  }

  if (isMe(toUid)) {
    amData.self_message = true
  }
  amData.message_category_1 = 'send_private'
  if (user.subscriptionSettings) {
    amData.subscription_type = user.subscriptionSettings[PRIVATE_MSG_PERMISSION] || '-1'
  }

  if (isBuffActive(toUid, GOOD_FOR_16) ||
    isBuffActive(toUid, GOOD_FOR_18)) {
    amData.message_category_2 = 'privateMsgToBroadcasterCostPlus'
    sendAmplitudeEvent('message', amData)
  } else if (isBuffActive(toUid, GOOD_FOR_12) ||
    isBuffActive(toUid, GOOD_FOR_14)) {
    amData.message_category_2 = 'privateMsgToBroadcasterCost'
    sendAmplitudeEvent('message', amData)
  } else {
    amData.message_category_2 = 'privateMsgCost'
    sendAmplitudeEvent('message', amData)
  }
}

function getDialogToUids(dialog) {
  return dialog.users_in_dialog.filter(uid => uid !== getMyUid()) || getMyUid()
}

export function getDialogs() {
  return getState('chat.dialogs', [])
}

