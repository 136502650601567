import is from 'is_js'
import controller from '@/controller'
import { state } from 'cerebral'
import { messagesRoute, postersRoute, postRoute } from '@/utils/routerHelpers'
import { setSessionId } from '@/utils'
import { loadUsers } from '@/utils/userLoader'
import { profilePanel } from '../../../components/Panels/types'
import { checkModerationMode } from '../../../utils/moderationUtil'
import { callToUserClick } from '../../../utils/call/callToUserClick'
import { sendAmplitudeEvent } from '../../../utils/GAEvent'
import { getMyLSProp, loadMyLSProps, setMyLSProps, updateLSProps } from '../../../utils/LocalStorageUtil'
import { MS_PWA } from '../../../utils/api/PWAUtil'
import { setStatusBarH, statusBarH } from '../../../utils/appUtil'
import { getAppId } from '../../../utils/twa'

let inited = false

const MY_CHARM_AI = 'mycharmai.com'

export const manageRouteArgs = ({ router, store, get, props }) => {
  // console.log('manage route args')
  const urlGetVariables = get(state`app.urlGetVariables`)
  const values = new URLSearchParams(urlGetVariables)

  const pathname = location.pathname.indexOf('/new-chat') !== -1
    ? location.pathname.substring(9)
    : location.pathname

  //запишем жесткий возрастной рейтинг на который не повлиюят конфиги с сервера
  if (values.get('ageRating')) {
    const xAge = parseInt(values.get('ageRating'))
    if (get(state`app.hardAgeRating`) !== xAge) {
      console.log('@@@ set hardAgeRating from url', xAge)
      store.set('app.hardAgeRating', Math.max(12, xAge))
      //не будем удалять возрастной рейтинг из URL что бы он не слетел при рефреше
      // values.delete('ageRating')
    }
  }
  if (values.get('noNSFW')) {
    console.log('noNSFW:', values.get('noNSFW'))
    store.set('app.noNSFW', values.get('noNSFW'))
  }

  if (values.get('subject')) {
    console.log('subject:', values.get('subject'))
    store.set('app.subject', values.get('subject'))
    values.delete('subject')
  }

  checkModerationMode()

  if (values.get('apid')) {
    store.set('app.oneSignalUserId', values.get('apid'))
  }
  if (values.get('udid')) {
    store.set('app.udid', values.get('udid'))
  }
  if (values.get('mobile')) {
    store.set('shop.disable', true)
    store.set('app.mobile', true)
  } else {
    store.set('app.mobile', false)
  }

  if (values.get('aiDolls')) {
    store.set('aiDolls', values.get('aiDolls'))
  } else if (location.origin.includes(MY_CHARM_AI)) {
    store.set('aiDolls', true)
  }

  if (values.get('platform')) {
    store.set('platform', values.get('platform'))
  }

  if (values.get('datingApp')) {
    store.set('datingApp', values.get('datingApp'))
  }
  //////TWA PARAMS
  if (values.get('appid')) {
    store.set('app.appId', values.get('appid'))
  } else if (document.referrer.includes('app-info://platform/microsoft-store')) {
    store.set('app.appId', MS_PWA) // getMyLSProp('pwaAppId') ||
  }
  if (values.get('gen')) {
    store.set('wrapperGen', values.get('gen'))
  }
  if (values.get('testApp')) {
    store.set('testApp', true)
  }
  if (values.get('autoStartStream')) {
    console.log('autoStartStream')
    store.set('autoStartStream', true)
  }

  if (values.get('sbh')) {
    console.log('SBH ' + values.get('sbh'))
    setStatusBarH(parseInt(values.get('sbh')) || 0)
    // store.set('statusBarH', parseInt(values.get('sbh')) || 0)
  }

  if (values.get('cdn_disable')) {
    store.set('cdnDisable', values.get('cdn_disable'))
  }
  if (values.get('v')) {
    store.set('app.twa_version', values.get('v'))
  }
  if (values.get('name')) {
    store.set('app.twa_app_name', values.get('name'))
  }
  if (values.get('installer')) {
    store.set('app.twa_app_installer', values.get('installer'))
  }
  if (values.get('isHuawei')) {
    store.set('app.huawei', values.get('isHuawei'))
  }
  if (values.get('payment')) {
    try {
      if (values.get('payment') !== 'error_loading_products') {
        const twaPaymentConfig = JSON.parse(values.get('payment'))
        setMyLSProps('lastPaymentIfo', twaPaymentConfig)
        store.set('app.twa_payment_config', twaPaymentConfig)
      } else {
        sendAmplitudeEvent('open_shop', {
          type: 'error_loading_products'
        })
      }
    } catch (e) {
      sendAmplitudeEvent('open_shop', {
        type: 'some_error'
      })
      // store.set('app.twa_payment_config', {})
    }
  } else {
    if (values.get('appid')) {
      const twaPaymentConfig = getMyLSProp('lastPaymentIfo')
      if (twaPaymentConfig) {
        sendAmplitudeEvent('open_shop', {
          type: 'use_last_payment'
        })
        store.set('app.twa_payment_config', twaPaymentConfig)
      } else {
        sendAmplitudeEvent('open_shop', {
          type: 'empty_last_payment'
        })
      }
    }
  }
  ////////

  if (values.get('fromPush') || values.get('fromDeepLink')) {
    if (!get(state`app.fromPush`)) {
      store.set('app.fromPush', pathname)
    }
  }

  if (values.get('rid')) {
    store.set('auth.partnerId', values.get('rid'))
  }

  if (values.get('from')) {
    store.set('auth.fromParam', values.get('from'))
  }

  if (values.get('mrtigraRefer')) {
    const refer = decodeURIComponent(values.get('mrtigraRefer'))
    values.delete('mrtigraRefer')
    store.set('auth.mrtigraRefer', refer)
    router.redirect(`${pathname}?${values.toString()}`)
  }

  //убрать это, когда сделаем безопасную передачу сессии через куки
  if (values.get('sid')) {
    try {
      const sid = values.get('sid')
      values.delete('sid')
      setSessionId(sid)
      // uploadOneSignalId()
      router.redirect(`${pathname}?${values.toString()}`)
      window.parent.postMessage(
        JSON.stringify(
          { type: 'clearSid', payload: null }
        ),
        '*'
      )
      return
    } catch (e) {
      console.warn(e)
    }
  }

  const lang = get(state`intl.lang`)

  if (values.get('callFrom')) {
    const getLastCaller = controller.getSequence('app.getLastCaller')
    getLastCaller()

    values.delete('callFrom')
    router.goTo(`/${lang}${postersRoute}/?${values.toString()}`)
  }

  if (values.get('profilechange')) {
    const myUid = get(state`auth.uid`)
    loadUsers([myUid], () => {
      const openProfile = controller.getSequence('app.createPanel')
      openProfile(profilePanel)
      values.delete('profilechange')
      router.goTo(`/${lang}/p`)
    })
  }

  if (!inited) {
    inited = true

    if (values.get('showComments')) {
      store.set('posts.showComments', true)
    }

    if (values.get('girlId')) {
      if (props.panelRoute === 'dialog') {
        router.goTo(`/${lang}${messagesRoute}/${values.get('girlId')}${urlGetVariables}`)
      } else {
        let girlPath = '/'
        if (values.get('post')) {
          if (is.mobile()) {
            router.goTo(`/${lang}${postRoute}/${values.get('post')}${urlGetVariables}`)
            return
          }
          girlPath = `/${lang}${postRoute}/${values.get('post')}${urlGetVariables}`
        }

        router.goTo(girlPath)
      }

      return
    }
    if (values.get('utm_campaign') === 'privateOffer') {
      const re = /[0-9abcdef]{32}/g
      const uids = pathname.match(re)
      if (uids && uids.length > 0) {
        callToUserClick(uids[0])
      }
      values.delete('utm_campaign')
    }

    if (values.get('openSupport')) {
      controller.getSequence('app.openSupport')()
    }
  }
}
